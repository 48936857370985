.pos-base {
  margin-top: 1.5%;
  color: white;
}

.skill-base-left {
  font-size: 1.5vmin;

  width: 15%;
  left: 2%;
  top: 0;
  float: left;
  position: sticky;
  position: -webkit-sticky;
}

.skill-base-right {
  font-size: 1.5vmin;

  width: 15%;
  right: 2%;
  top: 0;
  float: right;
  position: sticky;
  position: -webkit-sticky;
}

.skill-base-left h2, .skill-base-right h2 {
  margin-top: 0;
  color: #142f30;
  font-size: calc(90% + 0.41em);
}

.relatedskill {
  font-size: 1.2vmin;
  font-weight: 650;
  color: #388087;
  background-color: #c2edce;
  padding: 1pt; 
}

.animation {
  animation: blinkingText 3.2s infinite;
}

@keyframes blinkingText {
   
  0% {
      opacity: 0;
  }
  50% {
      opacity: .5;
  }
  100% {
      opacity: 1;
  }
}