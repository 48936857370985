.introduction {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30vmin;
  width: 50%;
  padding: 1vmin;
  border-radius: 15px;
  color: white;
  background-color: #6faab8a0;
  font-size: 1.7vmin;
  position: relative;
}

.x {
  width:20px;
  height:20px;
}