#myname {
  text-align: center;
  background-color: #388087;
  color: #c2edce;
  height: 9em;
  font-size: calc(5px + 1vmin);
}

#myname h1 {
  font-size: calc(5px + 2em);
  margin: 0.5vmin;
  padding: 0.5vmin;
}

#myname p {
  margin-top: 0pt;
}