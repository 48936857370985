.position {
  padding: 0.5%;
  color: #388087;
  background-color: #badfe7;
  font-weight: 494;
  font-size: 1.21vmin;
}

.position.highlight {
  color: #badfe7;
  background-color: #6faab8;
}

.positionspec {
  color: #142f30;
  font-style: oblique;
}

.description {
  font-weight: 500;
  text-align: left;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
}