.skill {
  color: #388087;
  background-color: #badfe7;
  font-size: calc(70% + 0.2em);
}

.skill.highlight {
  color: #badfe7;
  background-color: #6faab8;
}

h3.skill {
  padding: 4pt;
}