.app {
  text-align: center;
  background-color: #f6f6f2;
}

.app-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-footer {
  background-color: #388087;
  min-height: 50pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 0.5vmin);
  color: #c2edce;
}

.app-footer p {
  padding: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  color: #142f30;
}

.center {
  margin-left: auto;
  margin-right: auto;
  width: 62%;
}

[typeselected]:hover {
  cursor: pointer;
}

.blurall {
  position:fixed;
  height:100%;
  width: 100%;
  backdrop-filter: blur(10px);
  z-index: 10;
}